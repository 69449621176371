import React from 'react';
import {
  Container,
  Stack,
  Box,
  SimpleGrid,
  Text,
  Heading,
  Flex,
  Button,
  VStack,
} from '@chakra-ui/react';
import ImageSlider from './ImageSlider';
import { Link } from 'react-router-dom';

const Hero = (props) => {
  const SliderData = props.sliderdata;
  return (
    <>
      <Container id="about" maxW={'8xl'} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack>
            <VStack spacing={4}>
              <Heading p={2}>
                <Text>Rural Economic Development Association (REDA)</Text>
              </Heading>
              <Text fontSize={'lg'} align={'justify'}>
                Rural Economic Development Association (REDA) is a non-profit
                making community based non-governmental organization established
                in 1991 A.D. at Palpa District. It has been registered to
                District Administration Office Palpa in 1993 A.D and affiliated
                with Social Welfare Council in 1994 A.D.
              </Text>
              <Text fontSize={'lg'} align={'justify'}>
                It comprises 464 general members (male-298 and female-166) along
                with 17 Board members. The main objectives of REDA are enabling
                community people to identify and mobilize social, physical and
                human resources in achieving higher level of self-reliance. It
                facilitates communities enhancing their planning, implementing
                and monitoring capacities.
              </Text>
              <Button
                as={Link}
                ml={{ base: '2%', lg: '35%' }}
                mr={{ base: '50%', lg: '0%' }}
                mt={{ md: '1.5rem' }}
                rounded={'md'}
                size={'lg'}
                fontWeight={'bold'}
                px={6}
                bgColor="#208AAE"
                color="white"
                _hover={{ transform: 'scale(1.1)' }}
                to={'/about-us/introduction'}
              >
                View More
              </Button>
            </VStack>
          </Stack>
          <Flex>
            <Box color="white">
              {SliderData ? <ImageSlider slides={SliderData} /> : null}
            </Box>
          </Flex>
        </SimpleGrid>
      </Container>
    </>
  );
};

export default Hero;
