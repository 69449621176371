import React from 'react';
import {
  Center,
  Heading,
  Divider,
  Container,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import '../Projects/style.css';

const ProjectsHome = (props) => {
  const handleViewMoreClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const projects_list = props.projects;

  return (
    <>
      <Divider />
      <Container id="about" maxW={'8xl'} py={12}>
        <Center mb={30}>
          <Heading>Recent Projects</Heading>
        </Center>
        <Box className="main_services">
          {projects_list
            ? projects_list.map(({ photos, title, description, date, id }) => (
              <Box className="card_services" key={id}>
                <img src={photos[0].image} alt={title} />
                <Box className="card_services-body">
                  <h6>{title}</h6>
                  <Text noOfLines={[4, 6]}>{description}</Text>
                  <Center mt={2}>
                    <Link
                      to={`/projects/${id}`}
                      state={{
                        title: title,
                        date: date,
                        description: description,
                        photos: photos,
                        id: id,
                      }}
                    >
                      <Button
                        variant="link"
                        size={'lg'}
                        fontWeight={'bold'}
                        px={6}
                        color="#8DBF66"
                      >
                        Read More
                      </Button>
                    </Link>
                  </Center>
                </Box>
              </Box>
            ))
            : ''}
        </Box>
        <Center>
          <Button
            as={Link}
            rounded={'md'}
            size={'lg'}
            fontWeight={'bold'}
            mt={'1rem'}
            px={6}
            bgColor="#208AAE"
            color="white"
            _hover={{ transform: 'scale(1.1)' }}
            to={'/projects'}
            onClick={handleViewMoreClick}
          >
            View All Projects
          </Button>
        </Center>
      </Container>
    </>
  );
};

export default ProjectsHome;
