import React from 'react';
import {
  Box,
  Container,
  Link as ChakraLink,
  SimpleGrid,
  Stack,
  Text,
  Image,
  HStack,
  Spacer,
  Divider,
} from '@chakra-ui/react';
import logo from '../Navbar/image/logo.png';
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';
import { MdPhone, MdEmail, MdLocationOn, MdPrint } from 'react-icons/md';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function Footer() {
  const defaultProps = {
    center: {
      lat: 27.8325,
      lng: 83.4163,
    },
    zoom: 15,
  };

  return (
    <Box bg="#0E1F51" color="white" zIndex={1} bottom={0} className="footer">
      <Container
        as={Stack}
        maxW={'8xl'}
        py={12}
        justifyContent="center"
        alignItems="center"
      >
        <SimpleGrid
          templateColumns={{
            base: '1fr',
            sm: '3fr 2fr 1fr 3fr',
            md: '3fr 3fr 3fr 5fr',
          }}
          spacing={10}
          justifyContent={'center'}
        >
          <Stack spacing={3}>
            <Box>
              <Image
                alt={'REDA'}
                fit={'cover'}
                align={'center'}
                boxSize={'100px'}
                objectFit={'cover'}
                src={logo}
              />
              <Text fontSize={'sm'} mt={'5'} fontWeight={'bold'}>
                Rural Economic Development Association (REDA) is a non-profit
                making community based non-governmental organization established
                in 1991 A.D. at Palpa District.
              </Text>
            </Box>
          </Stack>
          <Stack align={'flex-start'} ml={{ base: 0, xl: 10 }}>
            <Text fontWeight={'bold'}>Important Links</Text>
            <Divider />
            <ChakraLink
              as={Link}
              to={'/about-us/introduction'}
              fontWeight={'bold'}
            >
              Our Information
            </ChakraLink>
            <ChakraLink as={Link} to={'/projects'} fontWeight={'bold'}>
              Projects
            </ChakraLink>
            <ChakraLink as={Link} to="/news&notices/news" fontWeight={'bold'}>
              News
            </ChakraLink>
            <ChakraLink
              as={Link}
              to="/news&notices/notices"
              fontWeight={'bold'}
            >
              Notices
            </ChakraLink>
            <ChakraLink as={Link} to={'/about-us/bod'} fontWeight={'bold'}>
              Board Members
            </ChakraLink>
            <ChakraLink as={Link} to={'/contact'} fontWeight={'bold'}>
              Contact Us
            </ChakraLink>
          </Stack>
          <Stack align={'flex-start'}>
            <Text fontWeight={'bold'}>Contact</Text>
            <Divider />
            <Text fontWeight={'bold'}>
              Rural Economic Development Association
            </Text>
            <HStack spacing={3}>
              <MdPhone color={'white'} size="20px" />
              <ChakraLink
                fontWeight={'bold'}
                color={'white'}
                href="tel:075-521610"
              >
                075-521610/521699
              </ChakraLink>
            </HStack>
            <HStack spacing={3}>
              <MdPrint color={'white'} size="20px" />
              <ChakraLink
                fontWeight={'bold'}
                color={'white'}
                href="tel:075-521610"
              >
                075-521610
              </ChakraLink>
            </HStack>
            <HStack spacing={3}>
              <MdLocationOn color={'white'} size="20px" />
              <ChakraLink
                href="https://goo.gl/maps/LtbLRvL2Hc77j5UM8"
                fontWeight={'bold'}
                color={'white'}
              >
                Palung Mainadi 6, Palpa, Nepal
              </ChakraLink>
            </HStack>
            <HStack spacing={3}>
              <MdEmail color={'white'} size="20px" />
              <ChakraLink
                fontWeight={'bold'}
                color={'white'}
                href="mailto:redapalpa@gmail.com"
              >
                {' '}
                redapalpa@gmail.com
              </ChakraLink>
            </HStack>
          </Stack>
          <Stack align={'flex-start'}>
            <Box
              height={{ base: '200px', xl: '100%' }}
              width={{ base: '100%', xl: '100%' }}
              bg={'#FFFFFF'}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyACdj0u3L_CoZPT0EPUkh2Iy501djYVu_g',
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent
                  lat={27.8325}
                  lng={83.4163}
                  text="My Marker"
                />
              </GoogleMapReact>
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
      <Container maxW={'10xl'} py={2} bg={'#DD703F'} color={'#FFFFFF'}>
        <HStack
          pl={{ base: '5%', lg: '20%', xl: '20%' }}
          pr={{ base: '5%', lg: '20%', xl: '20%' }}
        >
          <HStack align={'left'}>
            <Text>&copy;</Text>
            <Text fontWeight={'bold'}>
              Copyright 2023 REDA | All Rights Reserved
            </Text>
          </HStack>
          <Spacer></Spacer>
          <HStack align="right">
            <Text fontWeight={'bold'}>
              Powered By:{' '}
              <ChakraLink href="https://techoresolutions.com.np/">
                Techore Solutions
              </ChakraLink>
            </Text>
          </HStack>
        </HStack>
      </Container>
    </Box>
  );
}
