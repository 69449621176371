import React from "react";
import { Box, Image, Center } from "@chakra-ui/react";
// import { Helmet } from "react-helmet";
import NotFoundImage from "./images/notfound.jpg";
import { Helmet } from 'react-helmet';

export default function NotFound() {
    return (
        <>
            <Helmet>
                <title>Page Not Found | REDA</title>
            </Helmet>
            <Center mt={20}>
                <Box boxSize={"500px"}>
                    <Image src={NotFoundImage} />
                </Box>
            </Center>
        </>
    );
}