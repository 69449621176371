import React, { useState, useEffect } from 'react';
import {
  Container,
  Stack,
  Box,
  Text,
  Heading,
  Center,
  HStack,
  VStack,
  Button,
} from '@chakra-ui/react';
import '../../index.css';
import axios from 'axios';
import { API_URL, Username, Password } from '../../constants';
import IsLoading from '../../isLoading';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Career() {
  const [apiData, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const final_url = API_URL + 'careers/';
    const fetchData = async () => {
      try {
        const response = await axios.get(final_url, {
          auth: {
            username: Username,
            password: Password,
          },
        });
        const data = response.data;
        setIsLoading(false);
        if (data.length === 0) {
          setApiData(null);
        }
        else {
          setApiData(data);
        }
      } catch (error) {
        setIsLoading(false);
        setApiError(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Careers | REDA</title>
      </Helmet>
      <Stack minH={'80vh'}>
        <Container py={12} maxWidth={'6xl'}>
          <Center mb={'50px'}>
            <Heading className="heading">Our Openings</Heading>
          </Center>
          {isLoading ? (
            <IsLoading isLoading={true} />
          ) : (
            <>
              {apiData
                ? apiData.map(
                  ({
                    id,
                    position,
                    type,
                    location,
                    date,
                    your_work,
                    you_have,
                  }) => (
                    <Stack
                      pb="6"
                      m="4"
                      border={0}
                      borderBottom="1px solid"
                      borderBottomColor="gray.200"
                      key={id}
                    >
                      <VStack
                        display="grid"
                        gridTemplateColumns="repeat(2, 1fr)"
                      >
                        <Box>
                          <Text fontWeight="Bold">{position}</Text>
                          <HStack mt={'2'} spacing={5}>
                            <Text fontSize={'sm'}>{location}</Text>
                            <Text fontSize={'sm'}>{type}</Text>
                          </HStack>
                        </Box>
                        <Box textAlign={'right'}>
                          <Button
                            colorScheme="teal"
                            variant="outline"
                            as={Link}
                            to={`/careers/${id}`}
                            state={{
                              position: position,
                              type: type,
                              location: location,
                              date: date,
                              your_work: your_work,
                              you_have: you_have,
                              id: id,
                            }}
                          >
                            APPLY
                          </Button>
                        </Box>
                      </VStack>
                    </Stack>
                  )
                )
                :
                <Center>
                  <Heading mt={20}>Sorry! We currently have no job openings.</Heading>
                </Center>
              }
            </>
          )}
        </Container>
      </Stack>
    </>

  );
}
