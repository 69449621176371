import Hero from '../Hero';
import React from 'react';
import { Divider, Box, Container } from '@chakra-ui/react';
import ProjectsHome from './projects_home';
import BodyHome from './body_home';
import Message from './message_exectutive';
import IsLoading from '../../isLoading';
import MapHome from './maps_home';
import { Helmet } from 'react-helmet';

export default function Home(props) {
  const isLoading = props.loading;

  if (isLoading) {
    return (
      <Box minH={'100vh'}>
        <IsLoading isLoading={true} />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Home | REDA</title>
      </Helmet>
      <Container maxW="100%" px={5}>
        <Hero sliderdata={props.apiData.HomeSlider} />
        <Divider />
        <ProjectsHome projects={props.apiData.Projects} />
        <Divider />
        <Message />
        <Divider />
        <MapHome />
        <Divider />
        <BodyHome
          news={props.apiData.News}
          notices={props.apiData.Notices}
          careers={props.apiData.Careers}
        />
      </Container>
    </>

  );
}
