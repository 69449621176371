import React from 'react';
import GoogleMapReact from 'google-map-react';
import {
    Box,
    Container,
    Center,
    Heading,
} from '@chakra-ui/react';
import Marker from './markers';

const coordinates = [
    {
        lat: 27.8666632,
        long: 83.5499978,
        name: 'Palpa',
    },
    {
        lat: 28.0889,
        long: 83.2934,
        name: 'Gulmi',
    },
    {
        lat: 28.0197,
        long: 83.8049,
        name: 'Syangja',
    },
    {
        lat: 27.8940,
        long: 83.1220,
        name: 'Arghakhanchi',
    },
    {
        lat: 27.5330,
        long: 83.3789,
        name: 'Rupandehi',
    },
    {
        lat: 27.5518,
        long: 83.0469,
        name: 'Kapilbastu',
    },
    {
        lat: 28.3816,
        long: 82.6483,
        name: 'Rolpa',
    },
    {
        lat: 29.0539,
        long: 83.0791,
        name: 'Dolpa',
    },
]
const MapHome = () => {

    const defaultProps = {
        center: {
            lat: 28.2774,
            lng: 83.5816,
        },
        zoom: 8.25,
    };

    return (
        <>
            <Container maxW={"8xl"} py={12}>
                <Center mb={30}>
                    <Heading textAlign={"center"}>District's Served Through Different Initiative</Heading>
                </Center>
                <Center>
                    <Box style={{ height: '500px', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                        >
                            {coordinates.map(({ lat, long, name }) => (
                                <Marker
                                    lat={lat}
                                    lng={long}
                                    name={name}
                                    color="red"
                                />
                            ))}
                        </GoogleMapReact>
                    </Box>
                </Center>

            </Container>

        </>
    )
}

export default MapHome;