import React from 'react';
import {
    Box,
    Divider,
    Container,
    Button,
    SimpleGrid,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Center,
    Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { MdChevronRight } from 'react-icons/md';
import '../Projects/style.css';


const BodyHome = (props) => {
    const news_data = props.news;
    const notices_data = props.notices;
    const careers_data = props.careers;
    return (
        <>
            <Divider />
            <Container id="home_body" maxW={'8xl'} py={12} >
                <SimpleGrid
                    columns={{ base: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                    templateColumns={{ base: "100%", xl: "50% 48.5%" }}
                    spacing={5}
                >
                    <Box>
                        <Tabs variant="enclosed" >
                            <TabList>
                                <Tab>News</Tab>
                                <Tab>Career</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel boxShadow={'lg'}>
                                    <TableContainer>
                                        <Table variant="striped" borderBottom={0}>
                                            <Thead>
                                                <Tr>
                                                    <Th>Date</Th>
                                                    <Th>Title</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {news_data ? news_data.map(
                                                    ({ id, title, date, content, image, caption }) => (
                                                        <Tr key={id}>
                                                            <Td>{date}</Td>
                                                            <Td
                                                                _hover={{
                                                                    cursor: 'pointer',
                                                                    color: 'green.500',
                                                                    textDecoration: 'underline',
                                                                }}
                                                            ><Text as={Link}
                                                                to={`/news&notices/news/${id}`}
                                                                state={{ title: title, date: date, content: content, image: image, caption: caption, id: id }}>{title}</Text></Td>
                                                        </Tr>
                                                    )) : ''}
                                            </Tbody>
                                        </Table>
                                        <Center mt={5} mb={2}>
                                            <Box>
                                                <Button
                                                    bgColor="#208AAE"
                                                    color="white"
                                                    _hover={{ transform: 'scale(1.1)' }}
                                                    as={Link}
                                                    to="/news&notices/news"
                                                >View All
                                                </Button>
                                            </Box>
                                        </Center>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel boxShadow={'lg'}>
                                    <TableContainer>
                                        <Table variant="striped" borderBottom={0}>
                                            <Thead>
                                                <Tr>
                                                    <Th>Date</Th>
                                                    <Th>Position</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {careers_data ? careers_data.map(
                                                    ({ id, position, type, location, date, your_work, you_have }) => (
                                                        <Tr key={id}>
                                                            <Td>{date}</Td>
                                                            <Td
                                                                _hover={{
                                                                    cursor: 'pointer',
                                                                    color: 'green.500',
                                                                    textDecoration: 'underline',
                                                                }}
                                                            >
                                                                <Text as={Link}
                                                                    to={`/careers/${id}`}
                                                                    state={{ position: position, type: type, location: location, date: date, your_work: your_work, you_have: you_have, id: id }}>{position}</Text>
                                                            </Td>
                                                        </Tr>
                                                    )) : ''}
                                            </Tbody>
                                        </Table>
                                        <Center mt={5} mb={2}>
                                            <Box>
                                                <Button
                                                    bgColor="#208AAE"
                                                    color="white"
                                                    _hover={{ transform: 'scale(1.1)' }}
                                                    as={Link}
                                                    to="/careers"
                                                >View All
                                                </Button>
                                            </Box>
                                        </Center>
                                    </TableContainer>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                    <Box>
                        <Tabs variant="enclosed">
                            <TabList>
                                <Tab>Notices</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel boxShadow={'lg'}>
                                    <TableContainer >
                                        <Table variant="styled">
                                            <Tbody>
                                                {notices_data ? notices_data.map(
                                                    ({ title, date, id, document }) => (
                                                        <Tr key={id}>
                                                            <Td
                                                                _hover={{
                                                                    cursor: 'pointer',
                                                                    color: 'green.500',
                                                                    textDecoration: 'underline',
                                                                }}
                                                            >
                                                                <Box display="flex">
                                                                    <Box mr={2}>
                                                                        <MdChevronRight />
                                                                    </Box>
                                                                    <Text as={Link}
                                                                        to={`/news&notices/notices/${id}`}
                                                                        state={{ title: title, date: date, document: document, id: id }} >{title}</Text>
                                                                </Box>
                                                            </Td>
                                                        </Tr>
                                                    )) : ''}
                                            </Tbody>
                                        </Table>
                                        <Center mt={5} mb={2}>
                                            <Button
                                                bgColor="#208AAE"
                                                color="white"
                                                _hover={{ transform: 'scale(1.1)' }}
                                                as={Link}
                                                to="/news&notices/notices"
                                            >View All
                                            </Button>
                                        </Center>
                                    </TableContainer>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </SimpleGrid>
            </Container>
        </>
    );
};

export default BodyHome;
