import { Image } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// If you want to use your own Selectors look up the Advancaed Story book examples
const ImageSlider = ({ slides }) => {
    const itemwithIds = slides.map((item, index) => ({ ...item, id: index }));
    return (
        <Carousel infiniteLoop autoPlay showThumbs={false}>
            {itemwithIds.map((slide) => {
                return <Image key={slide.id} src={slide.image} maxHeight="500px" width="800px" objectFit="fill" />;
            })}
        </Carousel>
    );
};

export default ImageSlider;
