import React, { useState, useEffect } from 'react';
import {
  Container,
  Stack,
  Box,
  Text,
  Heading,
  Center,
  HStack,
  Button,
  Spacer,
} from '@chakra-ui/react';
import axios from 'axios';
import '../../index.css';
import { API_URL, Username, Password } from '../../constants';
import IsLoading from '../../isLoading';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Notice() {
  const [current_url, setCurrentURL] = useState(API_URL + 'notices/');
  const [apiData, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');
  const [previous, setPrevious] = useState(false);
  const [next, setNext] = useState(false);
  const [previousURL, setPreviousURL] = useState('');
  const [nextURL, setNextURL] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setCurrentURL(previousURL);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setCurrentURL(nextURL);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(current_url, {
          auth: {
            username: Username,
            password: Password,
          },
        });
        const data = response.data;
        setIsLoading(false);
        setApiData(data.results);
        if (data.links.next == null) {
          setNext(false);
        } else {
          setNext(true);
        }

        if (data.links.previous == null) {
          setPrevious(false);
        } else {
          setPrevious(true);
        }

        setNextURL(data.links.next);
        setPreviousURL(data.links.previous);
      } catch (error) {
        setIsLoading(false);
        setApiError(error);
      }
    };
    fetchData();
  }, [current_url]);

  useEffect(() => {
    if (isLoading) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isLoading]);

  return (
    <>
      <Helmet>
        <title>Notices | REDA</title>
      </Helmet>
      <Stack minH="100vh">
        <Container py={12} maxWidth={'3xl'}>
          <Box
            borderWidth="1px"
            boxShadow={'md'}
            borderRadius="4px"
            minHeight={{ base: 0, xl: '80vh' }}
          >
            {' '}
            <Center mb={'50px'}>
              <Heading mt={5} className="heading">
                Notices
              </Heading>
            </Center>
            {isLoading ? (
              <IsLoading isLoading={true} />
            ) : (
              <>
                {apiData
                  ? apiData.map(({ title, date, id, document }) => (
                    <Stack
                      pb="6"
                      m="4"
                      border={0}
                      borderBottom="1px solid"
                      borderBottomColor="gray.200"
                      mb={'50px'}
                      key={id}
                    >
                      <HStack p={2}>
                        <Text
                          fontWeight="Bold"
                          _hover={{ cursor: 'pointer', color: 'green.500' }}
                          as={Link}
                          to={`/news&notices/notices/${id}`}
                          state={{
                            title: title,
                            date: date,
                            document: document,
                            id: id,
                          }}
                        >
                          {title}
                        </Text>
                        <Spacer />
                        <Text>{date}</Text>
                      </HStack>
                    </Stack>
                  ))
                  : ''}
                <Center mb={5}>
                  <Box>
                    <HStack spacing={10}>
                      <Button
                        bgColor="#6e6e6e"
                        color="white"
                        _hover={{ transform: 'scale(1.1)' }}
                        onClick={handlePreviousPage}
                        disabled={!previous}
                      >
                        Previous
                      </Button>
                      <Text fontWeight={'bold'}>{currentPage}</Text>
                      <Button
                        bgColor="#6e6e6e"
                        color="white"
                        _hover={{ transform: 'scale(1.1)' }}
                        onClick={handleNextPage}
                        disabled={!next}
                      >
                        Next
                      </Button>
                    </HStack>
                  </Box>
                </Center>
              </>
            )}
          </Box>
        </Container>
      </Stack>
    </>

  );
}
