import React, { useState, useEffect } from 'react';
import {
  Container,
  Text,
  Heading,
  Card,
  CardHeader,
  Box,
  CardBody,
  CardFooter,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import ImageSlider from '../Hero/ImageSlider';
import NewLineToBr from '../../newLine';
import { API_URL, Username, Password } from '../../constants';
import { MdDateRange } from 'react-icons/md';
import IsLoading from '../../isLoading';
import '../../index.css';
import axios from 'axios';

export default function EachProject() {
  const data_received = useLocation().state;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');
  const [SliderData, setSliderData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const current_url = API_URL + `projects/${data_received.id}/`;
    const fetchData = async () => {
      try {
        const response = await axios.get(current_url, {
          auth: {
            username: Username,
            password: Password,
          },
        });
        setIsLoading(false);
        const received_data = response.data;
        setApiData(received_data);
        setSliderData(received_data.photos)
      } catch (error) {
        setApiError(error);
      }
    };
    fetchData();
  }, []);
  return (
    <Stack minH="100vh">
      {isLoading ? (
        <IsLoading isLoading={true} />
      ) : (<Container py={12} maxWidth={'4xl'}>
        <Card boxShadow={'0'}>
          <CardHeader>
            <HStack>
              <Heading mb={3}>
                {data.title}
              </Heading>
            </HStack>
            <HStack color="gray.500">
              <MdDateRange />
              <Text> {data.date}</Text>
            </HStack>
          </CardHeader>
          <CardBody>
            <Box p={4} color="white">
              <ImageSlider slides={SliderData} />
            </Box>
            <Heading as="h4" size="md" mt={4}>
              Project Description
            </Heading>
            <Text mt={3} align={"justify"}>
              <NewLineToBr>{data.description}</NewLineToBr>
            </Text>
          </CardBody>
          <CardFooter mx={'auto'}></CardFooter>
        </Card>
      </Container>
      )}
    </Stack>

  );
}
