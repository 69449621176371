import React, { useEffect, useState } from 'react';
import {
  Image,
  VStack,
  Heading,
  Center,
  Box,
  HStack,
  Link as ChakraLink,
  Text,
  Container,
} from '@chakra-ui/react';
import { leaders } from './constant';
import './styles.css';
import Card from './cards';
import { PhoneIcon } from '@chakra-ui/icons';
import Dummy from './images/dummy.png';
import { Helmet } from 'react-helmet';

export default function BoardMembers() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isLoaded]);

  return (
    <>
      <Helmet>
        <title>Board Members | REDA</title>
      </Helmet>
      <Container maxW={'8xl'} mt={10}>
        <Center mb={'50px'}>
          <Heading className="heading">Board Members</Heading>
        </Center>
        <Container mb={10}>
          <VStack>
            <Center mb={10} className="card-container_board">
              <VStack>
                <Box p={6}>
                  {' '}
                  <Image src={Dummy} borderRadius={'full'} />
                </Box>
                <VStack pb={3}>
                  <Heading my="2" size="md">
                    Gopal KC
                  </Heading>
                  <Text>Chairman</Text>
                </VStack>
                <HStack>
                  <ChakraLink href="tel:9857029998">
                    <PhoneIcon mr={1} color="black" fontSize={'20px'} />
                  </ChakraLink>
                  <Text
                    fontWeight={'bold'}
                    textColor="black"
                    as={ChakraLink}
                    href="tel:9857029998"
                  >
                    9857029998
                  </Text>
                </HStack>
              </VStack>
            </Center>
            <section className="container_board">
              {leaders.map(({ name, title, image, id, phone }) => (
                <Card
                  image={image}
                  name={name}
                  title={title}
                  key={id}
                  phone={phone}
                />
              ))}
              ;
            </section>
          </VStack>
        </Container>
      </Container>
    </>
  );
}
