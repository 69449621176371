import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Text,
  Heading,
  Link,
  Divider,
  Stack,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import '../../index.css';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import axios from 'axios';
import { API_URL, Username, Password } from '../../constants';
import IsLoading from '../../isLoading';

export default function EachNotice() {
  const data_received = useLocation().state;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');
  const [document, setDocument] = useState('');

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const getFilePluginInstance = getFilePlugin();
  const { DownloadButton } = getFilePluginInstance;

  useEffect(() => {
    setIsLoading(true);
    const current_url = API_URL + `notices/${data_received.id}/`;
    const fetchData = async () => {
      try {
        const response = await axios.get(current_url, {
          auth: {
            username: Username,
            password: Password,
          },
        });
        const data = response.data;
        setIsLoading(false);
        setApiData(data);
        setDocument(data.document);
      } catch (error) {
        setIsLoading(false);
        setApiError(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Stack minH="100vh">
      {isLoading ? (
        <IsLoading isLoading={true} />
      ) : (
        <Container py={12} maxWidth={'6xl'}>
          <Heading>
            {data.title}
          </Heading>
          <Divider />
          <Text color="gray.500" mt={2}>
            {data.date}
          </Text>

          <Box mt={15} height="70vh">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
              <div
                className="rpv-core__viewer"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <div>
                  <div
                    style={{
                      alignItems: 'center',
                      backgroundColor: '#eeeeee',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
                      display: 'flex',
                      padding: '4px',
                      float: "left",
                    }}
                  >
                    <DownloadButton />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      backgroundColor: '#eeeeee',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '4px',
                    }}
                  >
                    <ZoomOutButton />
                    <ZoomPopover />
                    <ZoomInButton />
                  </div>
                </div>

                <div
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                  }}
                >
                  <Viewer fileUrl={document} plugins={[zoomPluginInstance, getFilePluginInstance]} defaultScale={SpecialZoomLevel.PageFit} />
                </div>
              </div>;
            </Worker>
          </Box>
        </Container>
      )}
    </Stack>
  );
}
