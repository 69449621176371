import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import GoToTopButton from './goTop';
import Home from './components/Home';
import Information from './components/Information';
import BoardMembers from './components/BoardMembers';
import Staffs from './components/Staffs';
import Career from './components/Career';
import ContactForm from './components/Contact';
import Projects from './components/Projects';
import Notices from './components/Notices';
import ScrollToTop from './scrolltotop';
import News from './components/News';
import EachNews from './components/EachNews';
import EachCareer from './components/EachCareer';
import EachProject from './components/EachProject';
import EachNotice from './components/EachNotice';
import NotFound from './components/404';
import { API_URL, Username, Password } from './constants';
import axios from 'axios';

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: 'white',
      },
    }),
  },
});

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    const final_url = API_URL + 'home/';
    const fetchData = async () => {
      try {
        const response = await axios.get(final_url, {
          auth: {
            username: Username,
            password: Password,
          },
        });
        const data = response.data;
        setApiData(data);
        setIsLoading(false);
      } catch (error) {
        setApiError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home loading={isLoading} apiData={apiData} apiError={apiError} />
            }
          />
          <Route path="/about-us/introduction" element={<Information />} />
          <Route path="/about-us/bod" element={<BoardMembers />} />
          <Route path="/about-us/staffs" element={<Staffs />} />
          <Route path="/news&notices/notices" element={<Notices />} />
          <Route path="/news&notices/news" element={<News />} />
          <Route path="/news&notices/news/:id" element={<EachNews />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/careers/:id" element={<EachCareer />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<EachProject />} />
          <Route path="/news&notices/notices/:id" element={<EachNotice />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <GoToTopButton />
        <Footer />
      </Router>
    </ChakraProvider>
  );
};
export default App;
