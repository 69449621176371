import React, { useState, useEffect } from 'react';
import {
  Container,
  Text,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  HStack,
  Image,
  Link,
  UnorderedList,
  Box,
  Stack,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import NewListToBr from '../../newList';
import { MdLocationOn, MdDateRange } from 'react-icons/md';
import EachCareerImage from './images/career.jpg';
import '../../index.css';
import { API_URL, Username, Password } from '../../constants';
import axios from 'axios';
import IsLoading from '../../isLoading';

export default function Career() {
  const data_received = useLocation().state;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    setIsLoading(true);
    const current_url = API_URL + `careers/${data_received.id}/`;
    const fetchData = async () => {
      try {
        const response = await axios.get(current_url, {
          auth: {
            username: Username,
            password: Password,
          },
        });
        setIsLoading(false);
        const received_data = response.data;
        setApiData(received_data);
      } catch (error) {
        setApiError(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Stack minH="100vh">
      {isLoading ? (
        <IsLoading isLoading={true} />
      ) : (
        <Container py={12} maxWidth={'2xl'}>
          <Card borderRadius={'0'} border={'0'}>
            <CardHeader>
              <Text fontStyle="italic">{data.type}</Text>
              <Heading mb={3}>{data.position}</Heading>
              <hr style={{ width: '50%' }}></hr>
            </CardHeader>
            <CardBody>
              <Image
                src={EachCareerImage}
                objectFit="cover"
                w={'100%'}
                h={{ base: '100%', md: 250 }}
              />{' '}
              <HStack mt={4} color="gray.500">
                <MdLocationOn /> <Text>{data.location}</Text>
              </HStack>
              <HStack color="gray.500">
                {' '}
                <MdDateRange />
                <Text> {data.date}</Text>
              </HStack>
              <Text mt={5} mb={2} fontWeight={700}>
                YOUR WORK
              </Text>
              <UnorderedList fontSize="0.9rem">
                <NewListToBr>{data.your_work}</NewListToBr>
              </UnorderedList>{' '}
              <Text mt={5} mb={2} fontWeight={700}>
                YOU HAVE
              </Text>
              <UnorderedList fontSize="0.9rem">
                <NewListToBr>{data.you_have}</NewListToBr>
              </UnorderedList>
              <Box mt={5}>
                <Text>
                  If you are interested in the position please us send your
                  CV/Resume by clicking the button below
                </Text>
              </Box>
            </CardBody>
            <CardFooter mx={'auto'}>
              <Button
                as={Link}
                href="mailto:redapalpa@gmail.com"
                colorScheme="blue"
                variant="outline"
                style={{ textDecoration: 'none' }}
              >
                {' '}
                #REDA - APPLY
              </Button>
            </CardFooter>
          </Card>
        </Container>
      )}
    </Stack>
  );
}
