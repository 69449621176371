import React, { useEffect, useState } from 'react';
import { VStack, Heading, Center, Container } from '@chakra-ui/react';
import './styles.css';
import Card from './cards';
import { API_URL, Username, Password } from '../../constants';
import axios from 'axios';
import IsLoading from '../../isLoading';
import defaultImage from './images/dummy.png';
import { Helmet } from 'react-helmet';


export default function Staffs() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const current_url = API_URL + 'staffs/';
    const fetchData = async () => {
      try {
        const response = await axios.get(current_url, {
          auth: {
            username: Username,
            password: Password,
          },
        });
        setIsLoading(false);
        const data = response.data;
        setApiData(data);
      } catch (error) {
        setApiError(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Staffs | REDA</title>
      </Helmet>
      <Container maxW={'8xl'} mt={10}>
        <Center mb={'50px'}>
          <Heading className="heading">Staff Members</Heading>
        </Center>
        <Container mb={10}>
          <VStack>
            {isLoading ? (
              <IsLoading isLoading={true} />
            ) : (
              <section className="container_board">
                {apiData
                  ? apiData.map(
                    ({ position, phone, email, full_name, image, id }) => (
                      <Card
                        image={image ? image : defaultImage}
                        name={full_name}
                        title={position}
                        key={id}
                        phone={phone}
                        email={email}
                      />
                    )
                  )
                  : ''}
              </section>
            )}
          </VStack>
        </Container>
      </Container>
    </>
  );
}
