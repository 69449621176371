import React, { useState, useEffect } from 'react';
import {
  Container,
  Stack,
  Box,
  Text,
  Heading,
  Image,
  Divider,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import NewLineToBr from '../../newLine';
import '../../index.css';
import { API_URL, Username, Password } from '../../constants';
import axios from 'axios';
import IsLoading from '../../isLoading';

export default function EachNews() {
  const data_received = useLocation().state;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');
  useEffect(() => {
    setIsLoading(true);
    const current_url = API_URL + `news/${data_received.id}/`;
    const fetchData = async () => {
      try {
        const response = await axios.get(current_url, {
          auth: {
            username: Username,
            password: Password,
          },
        });
        setIsLoading(false);
        const received_data = response.data;
        setApiData(received_data);
      } catch (error) {
        setApiError(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Stack minH="100vh">
      {isLoading ? (
        <IsLoading isLoading={true} />
      ) : (
        <Container py={12} maxWidth={'6xl'}>
          <Heading>
            {data.title}
          </Heading>
          <Divider />
          <Text color="gray.500" mt={1}>
            {data.date}
          </Text>

          <br></br>

          <Stack justifyContent="center" alignItems="center">
            <Image
              src={data.image}
              objectFit="cover"
              maxHeight={"600px"}
            />{' '}
            <Heading as="h5" size="sm">
              {data.caption}
            </Heading>
            <Box>
              <Text mt={10} fontSize={20} align={"justify"}>
                <NewLineToBr>{data.content}</NewLineToBr>
              </Text>
            </Box>
          </Stack>
        </Container>
      )}
    </Stack>
  );
}
