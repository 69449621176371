import Dummy from './images/dummy.png';
export const leaders = [
  {
    id: 1,
    name: 'Lila Bahadur Karki',
    title: 'Executive Director',
    image: Dummy,
    phone: '9857029998',
  },
  {
    id: 2,
    name: 'Hari Bahadur Kunwar',
    title: 'Accountant',
    image: Dummy,
    phone: '9847374988',
  },
  {
    id: 3,
    name: 'Yam Bahadur Thadarai',
    title: 'Secretary',
    image: Dummy,
    phone: '9847098604',
  },
  {
    id: 4,
    name: 'Kamala Lamtange',
    title: 'Joint-Secretary',
    image: Dummy,
    phone: '9847305789',
  },
  {
    id: 5,
    name: 'Shiv Bahadur Kunwar',
    title: 'Joint-Accountant',
    image: Dummy,
    phone: '9857060936',
  },
  {
    id: 6,
    name: 'Narayan Kunwar',
    title: 'Member',
    image: Dummy,
    phone: '9857062099',
  },
  {
    id: 7,
    name: 'Kamala Kunwar',
    title: 'Member',
    image: Dummy,
    phone: '9847113144',
  },
  {
    id: 8,
    name: 'Laxman Kunwar',
    title: 'Member',
    image: Dummy,
    phone: '9847168101',
  },
  {
    id: 9,
    name: 'Lekh Bahadur Gaha',
    title: 'Member',
    image: Dummy,
    phone: '9857068573',
  },
  {
    id: 10,
    name: 'Saraswati Karki',
    title: 'Member',
    image: Dummy,
    phone: '9847175323',
  },
  {
    id: 11,
    name: 'Goon Bahadur Lamtari',
    title: 'Member',
    image: Dummy,
    phone: '9857068677',
  },
  {
    id: 12,
    name: 'Ashmita Khatri',
    title: 'Member',
    image: Dummy,
    phone: '9847027908',
  },
  {
    id: 13,
    name: 'Dhani Argali',
    title: 'Member',
    image: Dummy,
    phone: '9844732467',
  },
  {
    id: 14,
    name: 'Chet Bahadur Kunwar',
    title: 'Member',
    image: Dummy,
    phone: '9857060495',
  },
  {
    id: 1,
    name: 'Iman Singh Kunwar',
    title: 'Advisor',
    image: Dummy,
    phone: '9847227407',
  },
  {
    id: 16,
    name: 'Baburam Bahadur Karki',
    title: 'Advisor',
    image: Dummy,
    phone: '9857062797',
  },
  {
    id: 17,
    name: 'Dolakh Bahadur Karki',
    title: 'Advisor',
    image: Dummy,
    phone: '9847119144',
  },
];

export const members = [];
