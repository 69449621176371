import { Spinner, Box } from "@chakra-ui/react";

export default function IsLoading({ isLoading }) {
    return (
        <Box
            display={isLoading ? "block" : "none"}
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            zIndex="10"
        >
            <Spinner
                size="xl"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
            />
        </Box>
    );
}
