import React, { useState } from 'react';
import './markers.css';
import { MdLocationOn } from "react-icons/md";
import {
    Box, Text
} from '@chakra-ui/react';

const Marker = (props) => {
    const { color, name } = props;
    const [showText, setShowText] = useState(false);

    const handleMouseOver = e => {
        setShowText(true);
    };

    const handleMouseExit = e => {
        setShowText(false);
    };

    return (
        <Box position="relative">
            <Box
                position="absolute"
                top="-42px"
                left="50%"
                transform="translateX(-50%)"
                bg="white"
                p="5px 10px"
                rounded="lg"
                visibility={showText ? 'visible' : 'visible'}
            >
                <Text>{name}</Text>
            </Box>
            <Box
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseExit}
            >
                <MdLocationOn
                    className="marker"
                    style={{ color: color, cursor: 'pointer' }}
                />
            </Box>
        </Box>
    );
};

export default Marker;