import React, { useState, useEffect } from 'react';
import {
  Center,
  Heading,
  Divider,
  Container,
  Button,
  Box,
  Text,
  Stack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { API_URL, Username, Password } from '../../constants';
import axios from 'axios';
import './style.css';
import IsLoading from '../../isLoading';
import { Helmet } from 'react-helmet';

const Projects = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    setIsLoading(true);
    const current_url = API_URL + 'projects/';
    const fetchData = async () => {
      try {
        const response = await axios.get(current_url, {
          auth: {
            username: Username,
            password: Password,
          },
        });
        setIsLoading(false);
        const data = response.data;
        setApiData(data);
      } catch (error) {
        setApiError(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Projects | REDA</title>
      </Helmet>
      <Divider />
      <Stack minH="100vh">
        <Container id="projects" maxW={'8xl'} py={12}>
          <Center mb={'50px'}>
            <Heading className="heading">Our Projects</Heading>
          </Center>
          {isLoading ? (
            <IsLoading isLoading={true} />
          ) : (
            <Box className="main_services">
              {apiData
                ? apiData.map(({ photos, title, description, date, id }) => (
                  <Box className="card_services" key={id}>
                    <img src={photos[0].image} alt={title} />
                    <Box className="card_services-body">
                      <h6>{title}</h6>
                      <Text noOfLines={[4, 6]}>{description}</Text>
                      <Center mt={2} mb={2}>
                        <Link
                          to={`/projects/${id}`}
                          state={{
                            title: title,
                            date: date,
                            description: description,
                            photos: photos,
                            id: id,
                          }}
                        >
                          <Button
                            variant="link"
                            size={'lg'}
                            fontWeight={'bold'}
                            px={6}
                            color="#8DBF66"
                          >
                            Read More
                          </Button>
                        </Link>
                      </Center>
                    </Box>
                  </Box>
                ))
                : ''}
            </Box>
          )}
        </Container>
      </Stack>
    </>
  );
};

export default Projects;
