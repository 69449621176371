import React, { useState } from 'react'
import {
    FormControl,
    Flex,
    Input,
    Textarea,
    Button,
    Box,
    Container,
    Center,
    Heading,
    Text,
    Image,
    CircularProgress,
} from '@chakra-ui/react';
import ContactIMG from './images/contact.png';
import ErrorMessage from './errormessage';
import { API_URL, Username, Password } from '../../constants';
import axios from "axios";
import { Helmet } from 'react-helmet';

function ContactForm() {
    const [email, setEmail] = useState('');
    const [fullname, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [messageDisplay, setMessageDisplay] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const formSubmit = async (e) => {
        setIsLoading(true);
        const final_url = API_URL + "contactus/";
        e.preventDefault();
        try {
            await axios.post(
                final_url,
                {
                    "fullname": { fullname },
                    "email": { email },
                    "phone": { phone },
                    "message": { message },
                },
                {
                    auth: {
                        username: Username,
                        password: Password
                    }
                }
            );
            setFullName('');
            setEmail('');
            setMessage('');
            setPhone('');
            setMessageDisplay(true);
            setIsLoading(false);

        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };
    return (
        <>
            <Helmet>
                <title>Contact | REDA</title>
            </Helmet>
            <Container id="contact" maxW={"6xl"} py={12} overflow="hidden">
                <Center>
                    <Heading className="heading">Contact Us</Heading>
                </Center>
                <Flex
                    display="grid"
                    gridTemplateColumns={{ base: 'repeat(1, 3fr)', sm: 'repeat(1, 3fr)', md: 'repeat(1, 3fr)', lg: '1fr 2fr', xl: '1fr 2fr' }}
                    mb={{ base: '5px', lg: '50px' }}
                    rounded={{ base: "30", md: "40", lg: "50", xl: "50" }}
                    maxH={{ base: "100%", xl: "550px" }}
                >
                    <Box display="flex" alignItems="center">
                        <Image src={ContactIMG}></Image>
                    </Box>
                    <Box
                        bgColor={"white"}
                        borderRadius="lg"
                        p={{ base: 5, sm: 5, md: 5, lg: 10 }}
                        m={{ base: 5, sm: 5, md: 16, lg: 25 }}
                    >
                        <form onSubmit={formSubmit}>
                            {error && <ErrorMessage message={error} />}
                            <FormControl>
                                <Input
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    bg="#D3D3D3"
                                    _placeholder={{ color: '#545454' }}
                                    value={fullname}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt={6}>
                                <Input
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    bg="#D3D3D3"
                                    _placeholder={{ color: '#545454' }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt={6}>
                                <Input
                                    name="phone"
                                    type="tel"
                                    placeholder="Phone"
                                    bg="#D3D3D3"
                                    _placeholder={{ color: '#545454' }}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt={6}>
                                <Textarea
                                    name="message"
                                    bg="#D3D3D3"
                                    placeholder="Your Message"
                                    _placeholder={{ color: '#545454' }}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </FormControl>
                            <Button width="full" mt={4} type="submit" bg="#208AAE" color={"white"} _hover={{ transform: 'scale(1.01)' }}>
                                {isLoading ? (<CircularProgress isIndeterminate size="24px" color="teal" />) : ('Submit')}
                            </Button>
                        </form>
                        <Text id="success_message" mt={2} color={"green"} align="center" display={messageDisplay ? "block" : "none"}> Form has been submitted succesfully! We will get in touch with you soon.</Text>
                    </Box>
                </Flex >
            </Container>
        </>


    )
}

export default ContactForm
