import React, { useState, useEffect } from 'react';
import {
  Container,
  Stack,
  Heading,
  Center,
  HStack,
  SimpleGrid,
  Image,
  Box,
  Text,
  Button,
} from '@chakra-ui/react';
import { API_URL, Username, Password } from '../../constants';
import axios from 'axios';
import IsLoading from '../../isLoading';
import '../../index.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function News() {
  const [current_url, setCurrentURL] = useState(API_URL + "news/");
  const [apiData, setApiData] = useState([]);
  const [apiError, setApiError] = useState('');
  const [previous, setPrevious] = useState(false);
  const [next, setNext] = useState(false);
  const [previousURL, setPreviousURL] = useState('');
  const [nextURL, setNextURL] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setCurrentURL(previousURL);
  }

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setCurrentURL(nextURL);
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          current_url,
          {
            auth: {
              username: Username,
              password: Password
            }
          }
        );
        const data = response.data;
        setIsLoading(false);
        setApiData(data.results);
        if (data.links.next == null) {
          setNext(false);
        }
        else {
          setNext(true);
        }

        if (data.links.previous == null) {
          setPrevious(false);
        }
        else {
          setPrevious(true);
        }

        setNextURL(data.links.next);
        setPreviousURL(data.links.previous);
      } catch (error) {
        setIsLoading(false);
        setApiError(error);
      }
    };
    fetchData();
  }, [current_url])

  useEffect(() => {
    if (isLoading) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isLoading]);


  return (
    <>
      <Helmet>
        <title>News | REDA</title>
      </Helmet>
      <Stack minH="100vh">
        <Container py={12} maxWidth={'5xl'}>
          <Center mb={'50px'}>
            <Heading className="heading">Latest News</Heading>
          </Center>
          {isLoading ? <IsLoading isLoading={true} /> : <>{apiData ? apiData.map(({ id, title, date, content, image, caption }) => (
            <Stack
              pb="6"
              m="4"
              border={0}
              borderBottom="1px solid"
              borderBottomColor="gray.200"
              key={id}
            >
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 10, xl: 0 }}>
                <Box textAlign={'left'}>
                  <Stack>
                    <Image src={image} mx="auto" rounded="md" height={150} />
                  </Stack>
                </Box>
                <Box>
                  <Text
                    fontWeight="Bold"
                    _hover={{ cursor: 'pointer', color: 'green.500' }}
                    mb={3}
                    as={Link}
                    to={`/news&notices/news/${id}`}
                    state={{ title: title, date: date, content: content, image: image, caption: caption, id: id }}
                  >
                    {title}
                  </Text>
                  <Text noOfLines={[2, 3]}>{content}</Text>
                  <HStack mt={'2'}>
                    <Text fontSize={'sm'}>{date}</Text>
                  </HStack>
                </Box>
              </SimpleGrid>
            </Stack>
          )) : ''};
            <Center>
              <Box>
                <HStack spacing={10}>
                  <Button
                    bgColor="#6e6e6e"
                    color="white"
                    _hover={{ transform: 'scale(1.1)' }}
                    onClick={handlePreviousPage}
                    disabled={!previous}
                  >
                    Previous
                  </Button>
                  <Text fontWeight={"bold"}>{currentPage}</Text>
                  <Button
                    bgColor="#6e6e6e"
                    color="white"
                    _hover={{ transform: 'scale(1.1)' }}
                    onClick={handleNextPage}
                    disabled={!next}
                  >
                    Next
                  </Button>
                </HStack>
              </Box>
            </Center>
          </>}
        </Container>
      </Stack>
    </>

  );
}
