import React from 'react';
import {
  Heading,
  Divider,
  Container,
  HStack,
  SimpleGrid,
  Image,
  Stack,
  Text,
  Center,
  Box,
  VStack,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import ExecutiveDirector from './images/Executive_Director.jpg';

const ProjectsHome = () => {
  return (
    <>
      <Divider />
      <Container id="about" maxW={'8xl'} py={12}>
        <Center
          mb={10}
        >
          <Heading textAlign={"center"}
          >Message from the Executive Director</Heading>
        </Center>
        <SimpleGrid
          templateColumns={{
            base: '1fr',
            md: '0.8fr 3fr',
            lg: '1fr 2.5fr',
            xl: '0.8fr 3fr',
          }}
          my={5}
        >
          <VStack mt={{ base: 0, md: 0, lg: 20, xl: 0 }} >
            <Box mb={5}>
              <Image src={ExecutiveDirector} mx="auto" height={300} />
            </Box>
            <Heading fontSize={"25"}>Lila Bahadur Karki</Heading>
            <HStack>
              <Text fontWeight="Bold" mb={2}>
                Executive Director, REDA
              </Text>
            </HStack>
            <HStack
              mb={5}
              align={'left'}
              spacing={5}
              justify={{ base: 'center', md: ' start ' }}
            >
              <Box as={Link}>
                <EmailIcon boxSize="30px" />
              </Box>
              <Box as={Link}>
                <PhoneIcon boxSize="30px" />
              </Box>
            </HStack>
          </VStack>
          <Box>
            <VStack mr={{ base: 0, md: 0, lg: 0, xl: 25 }}>
              <Stack
                align={{ base: 'center', md: ' start ' }}
                justify={{ base: 'center', md: ' start ' }}
              >
                <Text mt={15} align="justify">
                  I am writing to convey my heartfelt gratitude for your ongoing
                  support of our aim to encourage local economic development.
                </Text>
                <Text mt={15} align="justify">
                  As we move forward, I'd like to remind everyone of our common
                  goal of creating vibrant and wealthy communities that thrive
                  on their own unique assets with the different tools and
                  techniques in the sectors of community mobilization, bio-mass
                  promotion, solar energy promotion, pico hydro promotion and
                  different skill development programs. To reach this aim, we
                  must remain committed to our values of collaboration,
                  creativity, and empowerment. We can all benefit from long-term
                  change if we work together.
                </Text>
                <Text mt={15} align="justify">
                  I am pleased to say that in recent years, we have had some new
                  projects, as well as collaborationswith local businesses and
                  organizations, and innovative programs to promote small
                  farmers and entrepreneurs. We are also looking into new methods
                  to use technology to better serve our members and run our
                  business.
                </Text>
                <Text mt={15} align="justify">
                  Our journey towards local economic development has not been easy, and we have faced many challenges along the way. However, through our determination, hard work, and the support of our partners and stakeholders, we have been able to achieve significant milestones and make a positive impact on the lives of many people.
                </Text>
                <Text mt={15} align="justify">
                  I encourage everyone to be interested and engaged with REDA as
                  we fight to achieve our vision of a brighter future for our
                  communities. Your suggestions and feedback are always welcome,
                  and we appreciate your input as we work to effect constructive
                  change.
                </Text>
              </Stack>
            </VStack>
          </Box>
        </SimpleGrid>
      </Container>
    </>
  );
};

export default ProjectsHome;
