import React from 'react';
import {
  Box,
  HStack,
  Flex,
  Image,
  Button,
  IconButton,
  useDisclosure,
  Stack,
  Text,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link as ChakraLink,
  Spacer,
} from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import logo from './image/logo.png';
import '../../../src/index.css';

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <>
      <Box
        bg={'white'}
        sx={{
          position: 'sticky',
          top: 0,
          '@supports': {
            position: '-webkit-sticky',
          },
        }}
        boxShadow="base"
        zIndex={999}
      >
        <HStack h={8} bgColor={'#DD703F'} pr={{ base: '5%' }}>
          <Spacer></Spacer>
          <HStack spacing={6}>
            <HStack>
              <ChakraLink href="mailto:redapalpa@gmail.com">
                <EmailIcon boxSize={5} mr={4} color="white" />
              </ChakraLink>
              <Text
                fontWeight={'bold'}
                display={{
                  base: 'none',
                  sm: 'none',
                  md: 'none',
                  lg: 'block',
                  xl: 'block',
                }}
                textColor="white"
                as={ChakraLink}
                href="mailto:redapalpa@gmail.com"
              >
                redapalpa@gmail.com
              </Text>
            </HStack>
            <HStack>
              <ChakraLink href="tel:7028073007">
                <PhoneIcon mr={4} color="white" />
              </ChakraLink>
              <Text
                fontWeight={'bold'}
                display={{
                  base: 'none',
                  sm: 'none',
                  md: 'none',
                  lg: 'block',
                  xl: 'block',
                }}
                textColor="white"
                as={ChakraLink}
                href="tel:7028073007"
              >
                075-521610/521699{' '}
              </Text>
            </HStack>
          </HStack>
        </HStack>
        <Center py={5} bgColor="white">
          <Flex id="navbar" h={16} justifyContent={'space-between'}>
            <HStack spacing={12}>
              {/* Logo starts here */}
              <Box>
                <Link to="/">
                  <Image
                    alt={'Techore Solutions'}
                    fit={'cover'}
                    align={'center'}
                    boxSize={'100px'}
                    objectFit={'cover'}
                    src={logo}
                    mr={40}
                    id="main-logo"
                  />
                </Link>
              </Box>
              {/* Logo ends here */}
              <HStack
                as={'nav'}
                spacing={9}
                display={{ base: 'none', md: 'flex' }}
              >
                <ChakraLink
                  as={Link}
                  fontWeight={'bold'}
                  px={2}
                  spacing={4}
                  display={{ base: 'none', md: 'flex' }}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'gray.300',
                  }}
                  to={'/'}
                >
                  Home
                </ChakraLink>
                <Menu closeOnSelect>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    bg={'white'}
                  >
                    About Us
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        navigate('/about-us/introduction');
                      }}
                    >
                      Our Information
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate('/about-us/bod');
                      }}
                    >
                      Board Members
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate('/about-us/staffs');
                      }}
                    >
                      Staff Members
                    </MenuItem>
                  </MenuList>
                </Menu>
                <ChakraLink
                  as={Link}
                  fontWeight={'bold'}
                  px={2}
                  spacing={4}
                  display={{ base: 'none', md: 'flex' }}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'gray.200',
                  }}
                  to={'/projects'}
                >
                  Our Projects
                </ChakraLink>
                <Menu closeOnSelect>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    bg={'white'}
                  >
                    News & Notices
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        navigate('/news&notices/news');
                      }}
                    >
                      News
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate('/news&notices/notices');
                      }}
                    >
                      Notices
                    </MenuItem>
                  </MenuList>
                </Menu>
                <ChakraLink
                  as={Link}
                  fontWeight={'bold'}
                  px={2}
                  spacing={4}
                  display={{ base: 'none', md: 'flex' }}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'gray.200',
                  }}
                  to={'/careers'}
                >
                  Career
                </ChakraLink>
                <ChakraLink
                  as={Link}
                  fontWeight={'bold'}
                  px={2}
                  spacing={4}
                  display={{ base: 'none', md: 'flex' }}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: 'none',
                    bg: 'gray.200',
                  }}
                  to={'/contact'}
                >
                  Contact Us
                </ChakraLink>
              </HStack>
            </HStack>
            <Flex
              ml={{ lg: 10, base: 5 }}
              mr={{ base: 5 }}
              alignItems={'center'}
            >
              <HStack>
                <IconButton
                  size={'lg'}
                  icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                  aria-label={'Open Menu'}
                  display={{ md: 'none' }}
                  onClick={isOpen ? onClose : onOpen}
                />
              </HStack>
            </Flex>
          </Flex>
        </Center>

        {isOpen ? (
          <Center>
            <Box
              pb={4}
              display={{ md: 'none' }}
              alignItems={'right'}
              ml={'50%'}
            >
              <Stack as={'nav'} spacing={4}>
                <Center>
                  <ChakraLink
                    as={Link}
                    fontWeight={'bold'}
                    spacing={4}
                    py={1}
                    rounded={'md'}
                    to={'/'}
                    onClick={isOpen ? onClose : onOpen}
                  >
                    Home
                  </ChakraLink>
                </Center>

                <Center>
                  <Menu closeOnSelect>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      bg={'white'}
                    >
                      About Us
                    </MenuButton>
                    <MenuList>
                      <MenuItem as={Link} to="/about-us/introduction" onClick={isOpen ? onClose : onOpen}>
                        Our Information
                      </MenuItem>
                      <MenuItem as={Link} to="/about-us/bod" onClick={isOpen ? onClose : onOpen}>
                        Board Members
                      </MenuItem>
                      <MenuItem as={Link} to="/about-us/staffs" onClick={isOpen ? onClose : onOpen}>
                        Staff Members
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Center>
                <Center>
                  <ChakraLink
                    as={Link}
                    fontWeight={'bold'}
                    spacing={4}
                    py={1}
                    rounded={'md'}
                    to={'/projects'}
                    onClick={isOpen ? onClose : onOpen}
                  >
                    Our Projects
                  </ChakraLink>
                </Center>
                <Center>
                  <Menu closeOnSelect>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      bg={'white'}
                    >
                      News & Notices
                    </MenuButton>
                    <MenuList>
                      <MenuItem as={Link} to="/news&notices/news" onClick={isOpen ? onClose : onOpen}>
                        News
                      </MenuItem>
                      <MenuItem as={Link} to="/news&notices/notices" onClick={isOpen ? onClose : onOpen}>
                        Notices
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Center>
                <Center>
                  <ChakraLink
                    as={Link}
                    fontWeight={'bold'}
                    spacing={4}
                    py={1}
                    rounded={'md'}
                    to={'/careers'}
                    onClick={isOpen ? onClose : onOpen}
                  >
                    Career
                  </ChakraLink>
                </Center>
                <Center>
                  <ChakraLink
                    as={Link}
                    fontWeight={'bold'}
                    spacing={4}
                    py={1}
                    rounded={'md'}
                    to={'/contact'}
                    onClick={isOpen ? onClose : onOpen}
                  >
                    Contact Us
                  </ChakraLink>
                </Center>
              </Stack>
            </Box>
          </Center>
        ) : null}
      </Box>
    </>
  );
};

export default Navbar;
