import React from 'react';
import {
  Container,
  Stack,
  Box,
  useColorModeValue,
  Text,
  Heading,
  Image,
  Center,
  SimpleGrid,
  Flex,
  Divider,
  ListItem,
  VStack,
  UnorderedList,
} from '@chakra-ui/react';
import './styles.css';
import solarimage from './images/solarenergy.jpg';
import womanwalkingimage from './images/ruralwoman.jpg';
import agri from './images/harvesting.jpg';
import { Helmet } from 'react-helmet';

export default function Information() {
  return (
    <>
      <Helmet>
        <title>Our Information | REDA</title>
      </Helmet>
      <Container id="about" maxW={'7xl'} py={12}>
        <Box spacing={10}>
          <Stack spacing={4}>
            <Center>
              <Heading p={2}>
                <Text color={useColorModeValue('#0E1F51', 'white')}>
                  Rural Economic Development Association (REDA)
                </Text>
              </Heading>
            </Center>
            <Text
              color={useColorModeValue('#242424', 'white')}
              fontSize={'lg'}
              align={'justify'}
            >
              Rural Economic Development Association is a community based
              non-governmental and non-profit making organization established in
              1991 A.D. in Palpa District with initiating of local people. It was
              registered in District Administration Office in B.S. in 1993 A.D.
              (Registered No- 34) and affiliated with Social Welfare Council in
              1994 A.D.There are 457 general members including 17 Board members.
              The number of male is 274 and number of female is 183. The main
              purpose of the establishment of this organization is to enable the
              community people on a self reliant way promoting socio-economic
              activities to full fill their socio-economic needs participating
              local community and mobilization of local resources on a friendly
              and environment way.
            </Text>
          </Stack>
        </Box>
        <Divider mt={{ base: '2rem', md: '4rem' }} borderWidth="1px" />
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4} p={{ md: 4 }} mr={'4rem'}>
            <Heading
              fontWeight={'italic'}
              className="section_title"
              color={useColorModeValue('#042483', 'white')}
              mt={{ base: '2rem', md: '9rem' }}
              mb={'1.3rem'}
            >
              Vision
            </Heading>
            <Text color={useColorModeValue('#242424', 'white')} fontSize={'lg'}>
              Creation of aware, independent, self-reliant society brings change
              in the socio-economic life of community people.
            </Text>
          </Stack>{' '}
          <Flex>
            <Box
              ml={{ base: 0, lg: 12, xl: 0 }}
              mt={{ base: '0.2rem', md: '5rem' }}
              p={{ md: 4 }}
            >
              <Image
                width={'100%'}
                rounded={'md'}
                alt={'feature image'}
                src={solarimage}
                objectFit={'cover'}
              />
            </Box>
          </Flex>
        </SimpleGrid>
        <Divider mt={{ base: '3rem', md: '5rem' }} borderWidth="1px" />

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Flex order={{ base: 1, md: 0 }}>
            <Box
              ml={{ base: 0, lg: 12, xl: 0 }}
              mt={{ base: 0, md: '5rem' }}
              p={{ md: 4 }}
            >
              <Image
                width={'100%'}
                rounded={'md'}
                alt={'feature image'}
                src={womanwalkingimage}
                objectFit={'cover'}
              />
            </Box>
          </Flex>
          <Stack spacing={4} p={{ md: 4 }} order={{ base: 0 }}>
            <Heading
              fontWeight={'italic'}
              className="section_title"
              color={useColorModeValue('#042483', 'white')}
              mt={{ base: '2rem', md: '11rem' }}
              mb={'1rem'}
            >
              Mission{' '}
            </Heading>
            <Text color={useColorModeValue('#242424', 'white')} fontSize={'lg'}>
              Develop self-independent, self-reliant society identifying and
              mobilizing economic, physical, human and natural resources
              availability in the community.
            </Text>
          </Stack>
        </SimpleGrid>
        <Divider mt={{ base: '3rem', md: '5rem' }} borderWidth="1px" />

        <VStack align={{ base: 'flex-start' }}>
          {' '}
          <Heading
            fontWeight={'italic'}
            className="section_title"
            color={useColorModeValue('#042483', 'white')}
            mt={{ base: '2.3rem', md: '3rem' }}
            mb={'1rem'}
            ml={'1rem'}
          >
            Objectives{' '}
          </Heading>
          <Center>
            <UnorderedList spacing={5} listStylePosition="inside">
              <ListItem>
                To improve socio-economic living standard of community people.
              </ListItem>
              <ListItem>
                To commercialized in cash crops (Ginger, Vegetable and Turmeric)
                and livestock sector.
              </ListItem>
              <ListItem>
                To enable Dalit, Janjati, Women and Disadvantage people increasing
                participation in decision-making.
              </ListItem>
              <ListItem>
                To initiate and promote transferring new technology through
                Extension Services.
              </ListItem>
              <ListItem>
                To promote instutionalized market mechanism (Ginger/Livestock).
              </ListItem>
              <ListItem>
                To promote and support infra-structure development (Drinking
                water, rural electrification, improve cooking stove, solar energy,
                improved water turbine, establishment collection centre).
              </ListItem>
              <ListItem>
                To promote and support awareness building programme (Health and
                sanitation, latrine construction, environment protection, family
                planning, HIV Aids, T.B. health education, mobile health
                services).
              </ListItem>
              <ListItem>
                To promote and strengthen Cooperative Organizations.
              </ListItem>
              <ListItem>
                To build up relation and cooperation with like minded local,
                regional, national and international organizations.
              </ListItem>
              <ListItem>
                To promote enterprise development and creates self-employment
                skills promoting the capacity of local service providers.
              </ListItem>
              <ListItem>
                To manage and facilitate saving and credit programme promoting
                self help groups.
              </ListItem>
              <ListItem>
                To provide qualitative services to the community enhancing social,
                technical and managerial skills of the organization.
              </ListItem>
            </UnorderedList>
          </Center>
        </VStack>

        <Divider mt={{ base: '3rem', md: '5rem' }} borderWidth="1px" />

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Flex>
            <Box
              ml={{ base: 0, lg: 12, xl: 0 }}
              mt={{ base: '3rem', md: '-2.4rem' }}
              p={{ md: 4 }}
            >
              <Image
                width={'100%'}
                rounded={'md'}
                alt={'feature image'}
                src={agri}
                objectFit={'cover'}
                mt={{ base: 0, md: '9rem' }}
              />
            </Box>
          </Flex>
          <Stack spacing={4} p={{ md: 4 }}>
            <Heading
              fontWeight={'italic'}
              className="section_title"
              color={useColorModeValue('#042483', 'white')}
              mt={{ base: '0.3rem', md: '3rem' }}
              mb={'1rem'}
              ml={'1rem'}
            >
              Working Modality{' '}
            </Heading>
            <Center>
              {' '}
              <UnorderedList spacing={4} listStylePosition="inside">
                <ListItem>
                  Giving emphasis to identify and mobilization of local resources.
                </ListItem>
                <ListItem>
                  Dalit, Janjati, Women and Dis-advantage groups are identified as
                  a Target Group.
                </ListItem>

                <ListItem>Careful in receiving the external resources.</ListItem>
                <ListItem>Mutual respect and co-operation.</ListItem>
                <ListItem>Maximum utilization of local resources.</ListItem>
                <ListItem>Materials as a means for self-reliant.</ListItem>
                <ListItem>
                  Instutionalized and promotion of Market promotion.
                </ListItem>
                <ListItem> How is more important than what is achieved.</ListItem>
                <ListItem>
                  Instutionalized Participatory Planning, Monitoring and
                  Evaluation.
                </ListItem>
                <ListItem>
                  Instutionalized Participatory Programme Review and Planning.
                </ListItem>
              </UnorderedList>
            </Center>
          </Stack>
        </SimpleGrid>
        <Divider mt={{ base: '3rem', md: '5rem' }} borderWidth="1px" />
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Flex>
            <Box
              ml={{ base: 0, lg: 12, xl: 0 }}
              mt={{ base: '3rem', md: '1rem' }}
              p={{ base: 4 }}
            >
              <VStack align={{ base: 'flex-start' }}>
                {' '}
                <Heading
                  fontWeight={'italic'}
                  className="section_title"
                  color={useColorModeValue('#042483', 'white')}
                  mt={{ base: '0.3rem', md: '3rem' }}
                  mb={'1rem'}
                >
                  Programme Launched{' '}
                </Heading>
                <UnorderedList spacing={5} listStylePosition="inside">
                  <ListItem>Ginger Promotion Programme</ListItem>
                  <ListItem>Livestock Programme (Buffalo/Goat)</ListItem>
                  <ListItem>Micro hydro Programme</ListItem>
                  <ListItem>Biomass Programme</ListItem>
                  <ListItem>Solar monitoring Programme</ListItem>
                  <ListItem>Rural Water and Sanitation Programme</ListItem>
                  <ListItem>Youth and Women Empowerment Programme</ListItem>
                  <ListItem>Saving and Credit Programme</ListItem>
                  <ListItem>Formal and Informal Education Programme</ListItem>
                </UnorderedList>
              </VStack>
            </Box>
          </Flex>
          <Stack spacing={4} p={{ base: 4 }}>
            <Heading
              fontWeight={'italic'}
              className="section_title"
              color={useColorModeValue('#042483', 'white')}
              mt={{ base: '0.3rem', md: '3rem' }}
              mb={'1rem'}
            >
              Donor Partner Organizations{' '}
            </Heading>{' '}
            <UnorderedList spacing={4} listStylePosition="inside">
              <ListItem>Helvetas (phase out)</ListItem>
              <ListItem>ADB (phase out)</ListItem>
              <ListItem>Heifer International, Nepal</ListItem>
              <ListItem>AEPC/ESAP (phase out)</ListItem>
              <ListItem>FUN Board</ListItem>
              <ListItem>DDC/VDC</ListItem>
              <ListItem>CLDP (ADB) DLSO</ListItem>
              <ListItem> District Agriculture Development Office</ListItem>
              <ListItem>LGCDP</ListItem>
              <ListItem>PAF </ListItem>
            </UnorderedList>
          </Stack>
        </SimpleGrid>
      </Container>
    </>

  );
}
