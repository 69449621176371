import {
    Center,
    Heading,
    Image,
    VStack,
    HStack,
    Box,
    Text,
    Link as ChakraLink
} from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';


export default function Card(props) {
    return (
        <Center mb={10} className="card-container_board">
            <VStack>
                <Box p={6}>
                    <Image
                        src={props.image}
                        alt={props.name}
                        borderRadius={'full'}
                    />
                </Box>
                <VStack pb={3}>
                    <Heading my='2' size='md'>
                        {props.name}
                    </Heading>
                    <Text >
                        {props.title}
                    </Text>
                </VStack>
                <HStack >
                    <ChakraLink href={`tel:${props.phone}`}>
                        <PhoneIcon mr={1} color='black' fontSize={'20px'} />
                    </ChakraLink>
                    <Text
                        fontWeight={"bold"}
                        textColor="black"
                        as={ChakraLink}
                        href={`tel:${props.phone}`}
                    >
                        {props.phone}
                    </Text>
                </HStack>
            </VStack>
        </Center >
    );
}